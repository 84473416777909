import {FC, useState, lazy, Suspense} from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import {Spin} from "antd";


import Layout from './components/Layout/Layout';
// import HomePage from './Pages/Home/HomePage';

const ServiceOne = lazy(() => import('./Pages/Services/Page/serviceOne'));
const ServiceTwo = lazy(() => import('./Pages/Services/Page/serviceTwo'));
const ServiceThree = lazy(() => import('./Pages/Services/Page/serviceThree'));
const ServiceFour = lazy(() => import('./Pages/Services/Page/serviceFour'));
const HomePage = lazy(() => import('./Pages/Home/HomePage'));
const AboutPage = lazy(() => import('./Pages/About/AboutPage'));
const ProjectPage = lazy(() => import('./Pages/Projects/ProjectPage'));
const ServicesPage = lazy(() => import('./Pages/Services/ServicesPage'));

const App: FC = () => {
  return (
    <div className="App">
        <Routes>
          <Route path='/' element={<Layout/>}>
            <Route path='/' index element={<HomePage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/services' element={<ServicesPage />} />
            <Route path='/services/post-1' element={<ServiceOne />} />
            <Route path='/services/post-2' element={<ServiceTwo />} />
            <Route path='/services/post-3' element={<ServiceThree />} />
            <Route path='/services/post-4' element={<ServiceFour />} />
            <Route path='/projects' element={<ProjectPage />} />
          </Route>
        </Routes>
    </div>
  );
}

export default App;
