import React from 'react';
import { Spin } from 'antd';

import './loadingPage.css'; 

const LoadingPage: React.FC = () => {
  return (
    <div className="loading-page">
      <Spin size="large" />
      <p>Загрузка, пожалуйста, подождите...</p>
    </div>
  );
};

export default LoadingPage;
