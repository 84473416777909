import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

import { Link } from 'react-router-dom';

import './footer.css';

import logo from "../../assets/img/logos.svg";


export default function Footer() {

//   function init() {
//     const myMap = new ymaps.Map("yandex-map", {
//         center: [51.119541, 71.455303],
//         zoom: 15
//     });

//     const myPlacemark = new ymaps.Placemark([51.119541, 71.455303], {
//         balloonContent: 'Мы здесь!'
//     });

//     myMap.geoObjects.add(myPlacemark);
// }

//   Map.ready(init);




  return (
    <div className='footer'>
      <div className="container">
        <div className="footer_content">
          <div className="footer_item">
            <div className="footer_logo">
              <img src={logo} alt="logo" className='logo' />
              <span>YIGROUP</span>
            </div>
            <p className="footer_desc">Товарищество с ограниченной ответсвенностью, 2007</p>
          </div>
          <div className="footer_item">
            <div className="footer-title">Навигация</div>
            <ul className='footer_nav'>
              <li><Link to='/about' className="nav_link">О нас</Link></li>
              <li><Link to='/services' className="nav_link">Сервисы</Link></li>
              <li><Link to='/projects' className="nav_link">Проекты</Link></li>
              <li><Link to='/contacts' className="nav_link">Контакты</Link></li>
            </ul>
          </div>
          <div className="footer_item">
            <div className="footer-title">Наш адрес</div>
            <a href='https://go.2gis.com/aods8' target="_blank" className='footer_location'>г. Алматы, ул. Утеген батыра 112</a>
            <div className="yandex-map">
              <YMaps>
                <Map width={"500px"} height={"300px"} defaultState={{ center: [43.239192, 76.863611], zoom: 15 }}>
                <Placemark geometry={[43.239192, 76.863611]} />
                </Map>
              </YMaps>
            </div>

          </div>
        </div>
      </div>
    </div>
     
  )
}
