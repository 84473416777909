import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../headers/Header';
import Footer from '../footer/Footer';
import LoadingPage from '../LoadingPage/LoadingPage';

import "./layout.css";

export default function Layout() {
  return (
    <>
      <Header />
      <Suspense fallback={<LoadingPage />}>
        <Outlet/>
      </Suspense>
      <Footer/>
    </>
  )
}
